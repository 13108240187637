import React, { useState } from 'react';
import { LanguageProvider } from './LanguageContext';
import './App.css';
import Header from './components/Header';
import FileUpload from './components/FileUpload';
import Description from './components/Description';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <div className="min-h-screen flex flex-col">
      <LanguageProvider>
        <Header />
        <Description />
        <FileUpload />
        <Contact />
        <Footer />
      </LanguageProvider>
    </div>
  );
}

export default App;
