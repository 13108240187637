import React, { useState } from 'react';
import { useLanguage } from '../LanguageContext';
import axios from 'axios';
import { Pie } from 'react-chartjs-2';
import Tooltip from './Tooltip';
import 'chart.js/auto';

const FileUpload = () => {
  const { language, toggleLanguage } = useLanguage();
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const onFileChange = (event) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (!selectedFile) {
      setMessage('Please select a file.');
      setFile(null);
      return;
    }
  
    const fileType = selectedFile.type;
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (selectedFile.size > maxSize) {
      setMessage('File size should not exceed 5MB.');
      setFile(null);
      return;
    }
    
    if (fileType === 'application/pdf' || fileType.startsWith('image/')) {
      setFile(selectedFile);
      setMessage('');
    } else {
      setMessage('Please upload a valid PDF or image file.');
      setFile(null);
    }
  };
    
  const onCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const onFileUpload = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    axios.post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_UPLOAD_ENDPOINT}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(response => {
        setResponseData(response.data);
        setMessage('File uploaded successfully!');
      })
      .catch(error => {
        setMessage(error.response ? error.response.data.detail : 'An error occurred');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const generateColorShades = (baseColor, count) => {
    const shades = [];
    const startValue = 50; // Change this value to make the darkest color lighter
    const step = Math.floor((255 - startValue) / (count - 1));
    for (let i = 0; i < count; i++) {
      const colorValue = 255 - step * i;
      shades.push(`rgb(${baseColor === 'blue' ? '0, 0' : '0, '}${colorValue}, ${baseColor === 'blue' ? colorValue : '0'})`);
    }
    return shades.reverse(); // Reverse the order to have the lightest color for the largest value
  };

  const getProductCO2Data = (products) => {
    const labels = products.map(product => (product.name.length > 20 ? product.name.substring(0, 30) + '...' : product.name));
    const data = products.map(product => {
      return Object.values(product.CO2).reduce((acc, value) => acc + value, 0);
    });
  
    // Sort data and labels
    const sortedData = data.map((value, index) => ({ value, label: labels[index] }))
      .sort((a, b) => b.value - a.value);
  
    const sortedLabels = sortedData.map(item => item.label);
    const sortedValues = sortedData.map(item => item.value);
    const backgroundColor = generateColorShades('blue', sortedValues.length);
  
    return {
      labels: sortedLabels,
      datasets: [
        {
          data: sortedValues,
          backgroundColor: backgroundColor,
        },
      ],
    };
  };
  
  const getCO2SumData = (CO2_sum) => {
    const data = [
      CO2_sum.resources,
      CO2_sum.processing,
      CO2_sum.manufacturing,
      CO2_sum.shipment,
      CO2_sum.energy,
    ];

    const labels = ['Resources', 'Processing', 'Manufacturing', 'Shipment', 'Energy'];

    // Sort data and labels
    const sortedData = data.map((value, index) => ({ value, label: labels[index] }))
      .sort((a, b) => b.value - a.value);

    const sortedLabels = sortedData.map(item => item.label);
    const sortedValues = sortedData.map(item => item.value);
    const backgroundColor = generateColorShades('green', sortedValues.length);

    return {
      labels: sortedLabels,
      datasets: [
        {
          data: sortedValues,
          backgroundColor: backgroundColor,
        },
      ],
    };
  };

  const renderPieChart = (data, type) => {
    const chartData = type === 'product' ? getProductCO2Data(data) : getCO2SumData(data);
    return <Pie data={chartData} />;
  };

  const onRetry = () => {
    setResponseData(null);
    setMessage('');
  };

  const formatCO2 = (value) => {
    return value.toFixed(2);
  };

  const getGoodnessColor = (value) => {
    if (value <= 0.33) return 'bg-red-500';
    if (value <= 0.66) return 'bg-orange-500';
    return 'bg-green-500';
  };

  const renderCO2Distribution = (CO2) => {
    const totalCO2 = Object.values(CO2).reduce((acc, value) => acc + value, 0);
    const distribution = Object.entries(CO2).map(([key, value]) => ({
      key,
      value,
      percentage: ((value / totalCO2) * 100).toFixed(2)
    }));
    const tooltips = {
      resources: (
        <div>
          <p>Emissions from the extraction and initial processing of raw materials, such as mining, logging, or oil extraction. These emissions are mainly due to fuel combustion and land use changes.</p>
        </div>
      ),
      processing: (
        <div>
          <p>Emissions in this stage arise from the energy-intensive processes needed to convert raw materials into usable forms. Examples include the processing of metals, chemicals, and food products. The CO₂ emissions result from the combustion of fuels used to power machinery, as well as chemical reactions that release CO₂ as a byproduct.</p>
        </div>
      ),
      manufacturing: (
        <div>
          <p>Emissions in manufacturing are associated with the operation of machinery, equipment, and tools required to produce final goods. While it can also involve some energy consumption, the type and intensity of energy use differ from processing. Manufacturing emissions can also come from indirect sources, such as electricity consumption.</p>
        </div>
      ),
      shipment: (
        <div>
          <p>Emissions from the transportation of raw materials, intermediate products, and finished goods. This includes emissions from trucks, ships, airplanes, and other modes of transportation.</p>
        </div>
      ),
      energy: (
        <div>
          <p>Emissions from the use of energy sources such as electricity, natural gas, and other fuels to power production facilities and equipment.</p>
        </div>
      ),
    };
    return (
      <div className="mt-4">
        <h4 className="text-lg font-semibold mb-2">CO<sub>2</sub> Distribution</h4>
        {distribution.map((item, index) => (
          <div key={index} className="mb-2 px-2">
            <span className="text-sm flex items-center">
              {item.key.charAt(0).toUpperCase() + item.key.slice(1)}: {item.percentage}%
              <Tooltip content={tooltips[item.key]}>
                  <svg className="w-5 h-5 ml-2 text-green-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm9.408-5.5a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2h-.01ZM10 10a1 1 0 1 0 0 2h1v3h-1a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-1v-4a1 1 0 0 0-1-1h-2Z" clip-rule="evenodd"/>
                  </svg>
              </Tooltip>
            </span>
            <div className="w-full bg-gray-200 rounded-full h-2 mb-2">
              <div className="bg-green-600 h-2 rounded-full" style={{ width: `${item.percentage}%` }}></div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  return (
    <div className="bg-gray-100 flex flex-col justify-center items-center p-6 shadow-inner">
      <div className="bg-white p-6 rounded shadow-md w-full max-w-4xl">
        {!responseData ? (
          <div className="text-center">
            {language === 'EN' ? (
              <h2 className="text-2xl font-bold mb-4">Upload a PDF invoice here</h2>
            ) : (
              <h2 className="text-2xl font-bold mb-4">PDF Rechnung hier hochladen</h2>
            )}
            <div className="mb-4 text-sm text-gray-700">
              {language === 'EN' ? (
                <p>
                  Please note that PDF invoices work better than images. PDFs should include text only and not scanned images. If there are scanned images, they should be in portrait orientation and not blurry. The maximum size for the File is 5MB and the page number must not exceed 5.
                </p>
              ) : (
                <p>
                  Bitte beachten Sie, dass PDF-Rechnungen besser funktionieren als Bilder. PDFs sollten nur Text und keine gescannten Bilder enthalten. Wenn gescannte Bilder vorhanden sind, sollten diese im Hochformat sein und nicht verschwommen. Die maximale File-Größe beträgt 5MB und die Seitenanzahl darf 5 nicht überschreiten.
                </p>
              )}
            </div>
            <div className="mb-4">
              <input
                type="file"
                accept="application/pdf,image/*"
                onChange={onFileChange}
                className="text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
              />
            </div>
            <div className="mb-4 flex items-center justify-center">
              <input
                type="checkbox"
                id="acceptTerms"
                onChange={onCheckboxChange}
                className="mr-2"
              />
              {language === 'EN' ? (
                <label htmlFor="acceptTerms" className="text-sm text-gray-700">
                  <span className="text-red-500">*</span> I acknowledge that this tool is intended for demonstration purposes only. I understand that BReact GmbH does not save uploaded data and that the results provided may be partly estimations for demonstration, which may not accurately reflect real data.
                </label>
            ) : (
                <label htmlFor="acceptTerms" className="text-sm text-gray-700">
                  <span className="text-red-500">*</span> Ich erkenne an, dass dieses Tool nur für Demonstrationszwecke gedacht ist. Ich nehme zur Kenntnis, dass BReact GmbH die hochgeladenen Daten nicht speichert und dass die bereitgestellten Ergebnisse teilweise Schätzungen zu Demonstrationszwecken sind, die möglicherweise nicht genau die tatsächlichen Daten widerspiegeln.
                </label>
              )}
            </div>
            {loading ? (
              <div className="relative flex justify-center items-center">
                <div className="loader"></div>
              </div>
            ) : (
              <>
              <button
                onClick={onFileUpload}
                className={`py-2 px-4 rounded transition duration-200 ${
                  !file || !isChecked ? 'bg-gray-500 text-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white hover:bg-blue-600'
                }`}
                disabled={!file || !isChecked}
              >
                Upload!
              </button>
              {message && (
                <p className="mt-4 text-red-500">{message}</p>
              )}
            </>
            )}
          </div>
        ) : (
            <div>
              {responseData.invoice ? (
                <div>
                  <h3 className="text-2xl font-bold mb-4">Results</h3>
                  <div className="bg-gray-100 p-4 rounded shadow-lg mb-6">
                    <h4 className="text-lg font-semibold mb-2">Basic Information</h4>
                    <div className="p-2">
                      <p><strong>Seller:</strong> {responseData.invoice.name_of_seller}</p>
                      <p><strong>Address:</strong> {responseData.invoice.address_of_seller}</p>
                      <p><strong>Buyer:</strong> {responseData.invoice.name_of_buyer}</p>
                      <p><strong>Address:</strong> {responseData.invoice.address_of_buyer}</p>
                      <p><strong>Order Date:</strong> {responseData.invoice.order_date}</p>
                    </div>
                  </div>
                  <div className="bg-gray-100 p-4 rounded shadow-lg mb-6">
                    <h4 className="text-lg font-semibold mb-2">Products</h4>
                    <div className="flex flex-wrap">
                      {responseData.invoice.products.map((product, index) => (
                        <div key={index} className="w-full md:w-1/2 p-2">
                          <div className="bg-white p-4 rounded-2xl shadow-lg mb-4">
                            <p><strong>Name:</strong> {product.name.length > 20 ? product.name.substring(0, 30) + '...' : product.name}</p>
                            <p><strong>Gross Price:</strong> €{product.price_gross.toFixed(2)}</p>
                            <p><strong>Net Price:</strong> €{product.price_net.toFixed(2)}</p>
                            <p><strong>Category:</strong> {product.category}</p>
                            <p><strong>CO<sub>2</sub>:</strong> {formatCO2(Object.values(product.CO2).reduce((acc, value) => acc + value, 0))} Kg</p>
                            {renderCO2Distribution(product.CO2)}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row">
                    <div className="w-full md:w-1/2 md:pr-3">
                      <div className="bg-gray-100 p-4 rounded shadow-lg mb-3">
                        <h4 className="text-lg font-semibold mb-2">Product CO<sub>2</sub> Emissions Percentage</h4>
                        {renderPieChart(responseData.invoice.products, 'product')}
                      </div>
                    </div>
                    <div className="w-full md:w-1/2 md:pl-3">
                      <div className="bg-gray-100 p-4 rounded shadow-lg mb-3">
                        <h4 className="text-lg font-semibold mb-2">CO<sub>2</sub> Emissions by Category</h4>
                        {renderPieChart(responseData.invoice.CO2_sum, 'category')}
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-100 p-4 rounded shadow-lg mt-3">
                    <h4 className="text-lg font-semibold">Total Footprint</h4>
                    <div className="flex flex-col md:flex-row justify-between items-center p-2">
                      <div>
                        <p><strong>Sum Gross:</strong> €{responseData.invoice.sum_gross.toFixed(2)}</p>
                        <p><strong>Sum Net:</strong> €{responseData.invoice.sum_net.toFixed(2)}</p>
                        <p><strong>Total CO<sub>2</sub>:</strong> {formatCO2(Object.values(responseData.invoice.CO2_sum).reduce((acc, value) => acc + value, 0))} Kg</p>
                      </div>
                      <div className="flex flex-col w-full md:w-2/5 mt-8 md:mt-0">
                        <div className="flex flex-wrap items-center justify-between mb-2">
                          <h4 className="text-lg font-semibold">Rating</h4>
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
                              {formatCO2(responseData.invoice.goodness * 100)}%
                            </span>
                          </div>
                        </div>
                        <div className="relative pt-1 w-full">
                          <div className="overflow-hidden h-4 mb-4 text-xs flex rounded bg-gray-200 w-full">
                            <div
                              style={{ width: `${responseData.invoice.goodness * 100}%` }}
                              className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${getGoodnessColor(responseData.invoice.goodness)}`}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="font-light">
                  {language === 'EN' ? (
                    <span>The uploaded document does not appear to be a valid invoice, please try again!</span>
                  ) : (
                    <span>Das hochgeladene Dokument scheint keine Rechnung zu sein, bitte noch einmal versuchen!</span>
                  )}
                </div>
              )}
            <button
              onClick={onRetry}
              className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-200 mt-4"
            >
              Retry
            </button>
          </div>
        )}
      </div>
    </div>
  );
  
};

export default FileUpload;
