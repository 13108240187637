import React from 'react';

const Header = () => {
  return (
    <div className="bg-gray-100 flex items-center p-4 md:p-6">
      <a href="https://breact.ai">
        <img src="images/logo.png" alt="Logo" className="w-40 md:w-64 mr-auto" />
      </a>
    </div>
  );
};

export default Header;
