import React from 'react';
import { useLanguage } from '../LanguageContext';

const Contact = () => {
    const { language, toggleLanguage } = useLanguage();

    return (
        <div className="flex flex-col items-center p-4 m-4 md:m-8">
            <div className="w-full max-w-4xl text-left">
                {language === 'EN' ? (
                    <div className="mb-4 whitespace-pre-line text-justify">
                        <h2 className="text-xl md:text-2xl font-bold mb-4">Contact Us</h2>
                        <p className="mb-4">Interested in implementing our full automation CO2 accounting tools? Contact us at <a href="mailto:office@breact.ai" className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline">office@breact.ai</a>. As a startup specializing in these solutions, we can help automate your future carbon accounting needs.</p>
                    </div>
                ) : (
                    <div className="mb-4 whitespace-pre-line text-justify">
                        <h2 className="text-xl md:text-2xl font-bold mb-4">Kontaktieren Sie uns</h2>
                        <p className="mb-4">Sind Sie daran interessiert, unsere vollständig automatisierten CO2-Bilanzierungstool zu implementieren? Kontaktieren Sie uns unter <a href="mailto:office@breact.ai" className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline">office@breact.ai</a>. Als Startup, das sich auf diese Lösungen spezialisiert hat, können wir Ihnen helfen, Ihre zukünftigen Anforderungen an die gesetzlich konforme CO2-Berichterstattung zu automatisieren.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Contact;
