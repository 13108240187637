import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-100 p-6">
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center">
        <div className="flex flex-col md:flex-row items-center">
          <a href="https://at.linkedin.com/company/breact-gmbh" className="mr-4 mb-4 md:mb-0">
            <img
              src="https://breact.ai/wp-content/uploads/2024/04/image.png"
              alt="LinkedIn"
              className="w-10 h-10"
            />
          </a>
          <div className="text-sm text-gray-700">
            <p className="mb-1">
              <a href="https://breact.ai/Impressum" className="hover:underline">Impressum</a>
            </p>
            <p className="mb-1">
              <a href="https://breact.ai/privacy-policy" className="hover:underline">Privacy Policy</a>
            </p>
            <p className="mb-1">
              <a href="http://breact.ai/cookie-policy" className="hover:underline">Cookie Policy</a>
            </p>
            <p>
              <a href="https://breact.ai/contact" className="hover:underline">Contact</a>
            </p>
          </div>
        </div>
        <div className="flex-grow text-center text-sm text-gray-700">
          <p>&copy; {new Date().getFullYear()} BReact GmbH. Alle Rechte vorbehalten.</p>
        </div>
        <div className="flex items-center">
          <figure className="ml-4">
            <img
              src="images/logo.png"
              alt="BReact Logo"
              className="w-40 h-auto"
            />
          </figure>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
