import React, { useState, useEffect } from 'react';
import { useLanguage } from '../LanguageContext';

const Description = () => {
    const [data, setData] = useState([]);
    const { language, toggleLanguage } = useLanguage();

    useEffect(() => {
        const interval = setInterval(() => {
            setData(currentData => [...currentData, Math.random() * 100]);
            if (data.length > 20) { // Limit data array length
                setData(currentData => currentData.slice(1));
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="flex flex-col items-center p-4 md:p-8">
            <div className="flex justify-end w-full max-w-4xl">
                <button
                    className={`mr-2 ${language === 'EN' ? 'font-bold' : ''}`}
                    onClick={() => toggleLanguage('EN')}
                >
                    EN
                </button>
                <button
                    className={`${language === 'DE' ? 'font-bold' : ''}`}
                    onClick={() => toggleLanguage('DE')}
                >
                    DE
                </button>
            </div>
            <div className="w-full max-w-4xl text-left">
                <h1 className="text-2xl md:text-4xl font-extrabold mb-4">
                    {language === 'EN' ? 'EcoTracker' : 'EcoTracker'}
                </h1>
                {language === 'EN' ? (
                    <div className="mb-4 whitespace-pre-line text-justify">
                        <p className="mb-8">Unlock the potential of your invoice data with EcoTracker, our advanced analytical tool. It meticulously extracts and analyzes invoice line items to provide comprehensive insights into your CO2 footprint.</p>
                        <h2 className="text-lg font-medium mb-1">Key Features</h2>
                        <ul className="list-disc list-inside">
                            <li><span className="font-medium">Advanced AI Analysis:</span> Utilizes Retrieval-Augmented Generation (RAG) for up-to-date, relevant data.</li>
                            <li><span className="font-medium">Accurate Reporting:</span> Leverages historical data for reliable estimations and clear, concise reports.</li>
                            <li><span className="font-medium">User-Friendly Interface:</span> Visually appealing insights for quick, informed decision-making.</li>
                        </ul>
                    </div>
                ) : (
                    <div className="mb-4 whitespace-pre-line text-justify">
                        <p className="mb-8">Entdecken Sie das Potenzial Ihrer Rechnungsdaten mit EcoTracker, unserem fortschrittlichen Analysetool. Es extrahiert und analysiert sorgfältig Rechnungspositionen, um umfassende Einblicke in Ihren CO2-Fußabdruck zu bieten.</p>
                        <h2 className="text-lg font-medium mb-1">Hauptmerkmale</h2>
                        <ul className="list-disc list-inside">
                            <li><span className="font-medium">Fortschrittliche KI-Analyse:</span> Nutzt Retrieval-Augmented Generation (RAG) für aktuelle, relevante Daten.</li>
                            <li><span className="font-medium">Genaues Reporting:</span> Verwendet historische Daten für zuverlässige Schätzungen und klare, prägnante Berichte.</li>
                            <li><span className="font-medium">Benutzerfreundliche Oberfläche:</span> Optisch ansprechende Einblicke für schnelle, fundierte Entscheidungen.</li>
                        </ul>
                    </div>
                )}

                <div className="mt-4">
                    <img src="/Ecotracker_Process_Animation.gif" alt="Flowchart Animation" className="w-full h-auto" />
                </div>
                {language === 'EN' ? (
                    <div className="mt-12 whitespace-pre-line text-justify">
                        <h2 className="text-xl md:text-2xl font-bold mb-4">Demo</h2>
                        <p className="mb-4">Try our demo by uploading a sample receipt in PDF format. Please note, this demo is for presentation purposes. Upon engagement, we ensure transparent and traceable reporting.</p>
                    </div>
                ) : (
                    <div className="mt-12 whitespace-pre-line text-justify">
                        <h2 className="text-xl md:text-2xl font-bold mb-4">Demo</h2>
                        <p className="mb-4">Probieren Sie unsere Demo aus, indem Sie eine Rechnung im PDF-Format hochladen. Bitte beachten Sie, dass diese Demo nur zu Präsentationszwecken dient. Bei Beauftragung gewährleisten wir eine transparente und nachvollziehbare Berichterstattung.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Description;
